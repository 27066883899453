import { Injectable } from '@angular/core';
import { HttpService } from 'services/http/http.service';
import { ShowIfHttpError } from '@certemy/common';
import {
  AssignmentSearchParameters,
  CertificationInfo,
  CreatePdfTemplateRequest,
  ManuallyGeneratePdfRequest,
  OrgNameAndId,
  OrgTemplateMappingRequest,
  OrgTemplateSettings,
  PdfTemplateField,
  PdfTemplateInfo,
  PhaseInfo,
  StateData,
} from './pdf-template.interface';
import { Observable } from 'rxjs';
import { buildQueryParams, createQueryParam } from 'utils/helpers';
import { ListRequestParams } from 'utils/interfaces';

@Injectable()
export class PdfTemplateService {
  constructor(private http: HttpService) {}

  @ShowIfHttpError('An error occurred.')
  createTemplate(payload: CreatePdfTemplateRequest) {
    return this.http.post('/pdf-generation/create-pdf-template', payload, { responseType: 'text' });
  }

  @ShowIfHttpError('An error occurred.')
  editTemplate(payload: CreatePdfTemplateRequest) {
    return this.http.post('/pdf-generation/edit-pdf-template', payload, { responseType: 'text' });
  }

  @ShowIfHttpError('An error occurred.')
  assignTemplate(payload: OrgTemplateMappingRequest) {
    return this.http.post('/pdf-generation/assign-pdf-template', payload, { responseType: 'text' });
  }

  @ShowIfHttpError('An error occurred.')
  editAssignment(payload: OrgTemplateSettings) {
    return this.http.post('/pdf-generation/edit-pdf-template-assignment', payload, { responseType: 'text' });
  }

  @ShowIfHttpError('An error occurred.')
  generatePdf(payload: ManuallyGeneratePdfRequest) {
    return this.http.post('/pdf-generation/manually-generate-and-upload', payload, { responseType: 'text' });
  }

  @ShowIfHttpError('An error occurred.')
  searchForAssignment(payload: AssignmentSearchParameters): Observable<OrgTemplateSettings> {
    return this.http.post('/pdf-generation/find-assignment', payload, { responseType: 'text' });
  }

  @ShowIfHttpError('An error occurred.')
  getTemplates(latest: boolean): Observable<PdfTemplateInfo[]> {
    return this.http.get(`/pdf-generation/get-templates?latest=${latest.toString()}`);
  }

  @ShowIfHttpError('An error occurred.')
  getTemplate(pdfTemplateId: number): Observable<PdfTemplateInfo> {
    return this.http.get(`/pdf-generation/get-template/${pdfTemplateId}`);
  }

  @ShowIfHttpError('An error occurred.')
  getTemplateFields(pdfTemplateId: number): Observable<PdfTemplateField[]> {
    return this.http.get(`/pdf-generation/get-template-fields/${pdfTemplateId}`);
  }

  @ShowIfHttpError('An error occurred.')
  getTemplatesForOrg(organizationId: number): Observable<PdfTemplateInfo[]> {
    return this.http.get(`/pdf-generation/get-templates-for-org/${organizationId}`);
  }

  @ShowIfHttpError('An error occurred.')
  getWorkflowsForOrg(organizationId: number): Observable<CertificationInfo[]> {
    return this.http.get(`/pdf-generation/workflows/${organizationId}`);
  }

  @ShowIfHttpError('An error occurred.')
  getVersionsForWorkflow(certificationId: number): Observable<CertificationInfo[]> {
    return this.http.get(`/pdf-generation/workflow-versions/${certificationId}`);
  }

  @ShowIfHttpError('An error occurred.')
  getAssigmentsForOrg(organizationId: number): Observable<OrgTemplateSettings[]> {
    return this.http.get(`/pdf-generation/get-assignments-for-org/${organizationId}`);
  }

  @ShowIfHttpError('An error occurred.')
  getDraftsForOrg(organizationId: number): Observable<OrgTemplateSettings[]> {
    return this.http.get(`/pdf-generation/get-drafts-for-org/${organizationId}`);
  }

  @ShowIfHttpError('An error occurred.')
  getAssigmentDetails(assignmentId: number): Observable<OrgTemplateSettings> {
    return this.http.get(`/pdf-generation/get-assignment-details/${assignmentId}`);
  }

  @ShowIfHttpError('An error occurred.')
  getPhasesAndStepsForWorkflow(certificationId: number): Observable<PhaseInfo[]> {
    return this.http.get(`/pdf-generation/phases-and-steps/${certificationId}`);
  }

  @ShowIfHttpError('An error occurred.')
  getOrgsForTemplate(pdfTemplateId: number): Observable<OrgNameAndId[]> {
    return this.http.get(`/pdf-generation/get-orgs-by-template/${pdfTemplateId}`);
  }

  @ShowIfHttpError('An error occurred.')
  getOrgsTemplateSettings(orgId: number, pdfTemplateId: number): Observable<OrgTemplateSettings> {
    return this.http.get(`/pdf-generation/get-org-template-settings/${orgId}/${pdfTemplateId}`);
  }

  @ShowIfHttpError('Cannot receive professionals.')
  getProfessionals(
    { search = null, order_id = null, order_type = 'asc', filters = null, page = 1, page_size = 10 }: ListRequestParams,
    organizationId: number,
    pdfTemplateId: number,
    certificationId: number,
    incomplete: boolean,
  ) {
    const offset = (page - 1) * page_size;
    const params = [
      createQueryParam('search', search),
      createQueryParam('filters', filters),
      createQueryParam('orderId', order_id),
      createQueryParam('orderType', order_type),
      createQueryParam('offset', offset),
      createQueryParam('limit', page_size),
      createQueryParam('certificationId', certificationId),
      createQueryParam('pdfTemplateId', pdfTemplateId),
      createQueryParam('incomplete', incomplete),
    ];
    const queryParams = buildQueryParams(params);

    return this.http.get(`/pdf-generation/professional/${organizationId}${queryParams}`);
  }

  @ShowIfHttpError('Cannot get states.')
  getStates(countryId: number): Observable<StateData[]> {
    return this.http.get(`/pdf-generation/states/${countryId}`);
  }

  @ShowIfHttpError('An error occurred.')
  getPreviousAssignment(certificationId: number, pdfTemplateId: number): Observable<number> {
    return this.http.get(`/pdf-generation/previous-assignment/${certificationId}/${pdfTemplateId}`);
  }

  @ShowIfHttpError('An error occurred.')
  getPreviousAssignmentDetails(pdfTemplateId: number, assignmentId: number): Observable<OrgTemplateSettings> {
    return this.http.get(`/pdf-generation/copy-assignment-info/${pdfTemplateId}/${assignmentId}`);
  }

  @ShowIfHttpError('An error occurred.')
  getCurrentAssignment(
    organizationId: number,
    pdfTemplateId: number,
    certificationId: number,
  ): Observable<OrgTemplateSettings> {
    return this.http.get(
      `/pdf-generation/clone-assignment-info?organizationId=${organizationId}&pdfTemplateId=${pdfTemplateId}&certificationId=${certificationId}`,
    );
  }
}
